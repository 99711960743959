.clbr-badge {
  width: max-content;
  padding: 5px 7px;
  margin-right: 5px;
}

.clbr-badge.info {
  background-color: rgba(52, 120, 246, 0.2) !important;
  color: #3478f6;
}

.clbr-badge.active {
  width: 65px;
  background-color: rgba(39, 174, 96, 0.2) !important;
  color: #27ae60;
}

.clbr-badge.inactive {
  width: 65px;
  background-color: rgba(130, 134, 155, 0.4) !important;
  color: #82869b;
}
