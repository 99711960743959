.bs-popover-right[x-placement^="bottom"] .arrow::before {
  border-bottom-color: white;
}

.bs-popover-right[x-placement^="top"] .arrow::before {
  border-top-color: white;
}

.bs-popover-right[x-placement^="left"] .arrow::before {
  border-left-color: white;
}

.bs-popover-right[x-placement^="right"] .arrow::before {
  border-right-color: white;
}
