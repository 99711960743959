a {
  text-decoration: none;
}

/* Customization styles for react-select components using classname prefixes */
.clbr-select-container .clbr-select__control {
  border-radius: 0.375rem;
}

.clbr-select-container .clbr-select__control--is-focused {
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.clbr-select-container .clbr-select__input:focus {
  box-shadow: none;
}

.clbr-select-container .clbr-select__menu {
  z-index: 5;
}

/* Messenger */
#clbr-messenger .clbr-channel-card {
  background-color: #ffffff;
  cursor: pointer;
  padding: 5px 15px;
}

#clbr-messenger .clbr-channel-card.active,
#clbr-messenger .clbr-channel-card:hover {
  background-color: #f8f8f8;
}

#clbr-messenger .clbr-message-items-container > div:first-child {
  margin-top: auto;
}

#clbr-messenger .input-container {
  width: 100%;
  border-radius: 20px;
  padding: 7px 14px;
  border: 1px solid #fafafa;
  color: #212529;
  background-color: #f0f2f5;
  font-size: 15px;
}

#clbr-messenger .text-input-block {
  outline: none;
}

#clbr-messenger .text-input-block[contentEditable="true"]:empty:before {
  content: attr(data-placeholder);
  color: #939293;
}

#clbr-messenger .clbr-message-item:hover {
  background-color: #f8f8f8;
}

.bi-x-circle-fill::before {
  vertical-align: top;
}

.clbr-reply-pipe {
  background: red;
  height: 14px;
  position: relative;
  width: 5px;
  left: 20px;
}
.clbr-reply-pipe:after {
  background: red;
  content: "";
  height: 5px;
  left: 20px;
  position: absolute;
  bottom: 9px;
  width: 50px;
}
