a:hover {
  text-decoration: none;
}
.active_nav {
  font-family: Circular, serif;
  color: #808297;
  letter-spacing: 0.25px;
}
.active_nav:hover {
  font-family: Circular-Medium, serif;
  color: #01052f;
  letter-spacing: 0;
}
