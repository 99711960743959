html {
  height: 100%;
}

body {
  height: 100%;
}

@font-face {
  font-family: "Circular-Thin";
  src: url("assets/fonts/Circular/CircularXXWeb-Thin.woff") format("woff");
}

@font-face {
  font-family: "Circular-ThinItalic";
  src: url("assets/fonts/Circular/CircularXXWeb-ThinItalic.woff") format("woff");
}

@font-face {
  font-family: "Circular-Light";
  src: url("assets/fonts/Circular/CircularXXWeb-Light.woff") format("woff");
}

@font-face {
  font-family: "Circular-LightItalic";
  src: url("assets/fonts/Circular/CircularXXWeb-LightItalic.woff")
    format("woff");
}

@font-face {
  font-family: "Circular";
  src: url("assets/fonts/Circular/CircularXXWeb-Regular.woff") format("woff");
}

@font-face {
  font-family: "Circular-Italic";
  src: url("assets/fonts/Circular/CircularXXWeb-Italic.woff") format("woff");
}

@font-face {
  font-family: "Circular-Book";
  src: url("assets/fonts/Circular/CircularXXWeb-Book.woff") format("woff");
}

@font-face {
  font-family: "Circular-BookItalic";
  src: url("assets/fonts/Circular/CircularXXWeb-BookItalic.woff") format("woff");
}

@font-face {
  font-family: "Circular-Medium";
  src: url("assets/fonts/Circular/CircularXXWeb-Medium.woff") format("woff");
}

@font-face {
  font-family: "Circular-MediumItalic";
  src: url("assets/fonts/Circular/CircularXXWeb-MediumItalic.woff")
    format("woff");
}

@font-face {
  font-family: "Circular-Bold";
  src: url("assets/fonts/Circular/CircularXXWeb-Bold.woff") format("woff");
}

@font-face {
  font-family: "Circular-BoldItalic";
  src: url("assets/fonts/Circular/CircularXXWeb-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Circular-Black";
  src: url("assets/fonts/Circular/CircularXXWeb-Black.woff") format("woff");
}

@font-face {
  font-family: "Circular-BlackItalic";
  src: url("assets/fonts/Circular/CircularXXWeb-BlackItalic.woff")
    format("woff");
}

@font-face {
  font-family: "Circular-ExtraBlack";
  src: url("assets/fonts/Circular/CircularXXWeb-ExtraBlack.woff") format("woff");
}

@font-face {
  font-family: "Circular-ExtraBlackItalic";
  src: url("assets/fonts/Circular/CircularXXWeb-ExtraBlackItalic.woff")
    format("woff");
}

.pointer {
  cursor: pointer;
}

.max-height-100 {
  height: 100%;
}

.bgGray {
  background-color: #f2f3f5;
}

.section-header {
  height: 65px;
  display: flex;
  align-items: center;
}

.bordered-item {
  border-radius: 8px;
}

.light-placeholder::placeholder {
  color: #aeb1bf;
}
/** Responsive styles **/
.container-16 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: auto;
  margin-right: auto;
}

.row-cal {
  display: flex;
  flex-wrap: wrap;
}

.col-16 {
  margin: 16px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}

.margin-13-12 {
  margin: 13px 12px;
}

.general-btn {
  background-color: transparent;
  border: none;
  color: black;
  padding: 12px 16px;
  cursor: pointer;
}

.general-btn:hover {
  background-color: #f2f3f5;
}

.borderless {
  border: 0;
}

/** Text Styles **/

.circular-font {
  font-family: Circular, serif;
}
.circular-bold-font {
  font-family: Circular-Bold, serif;
}
.circular-medium-font {
  font-family: Circular-Medium, serif;
}
.bold {
  font-family: Circular-Bold, serif;
}
.medium-bold {
  font-family: Circular-Medium, serif;
}

.heading-large {
  font-family: Circular-Bold, serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 32px;
}

.heading-normal {
  font-family: Circular-Bold, serif;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 28px;
}

.heading-medium {
  font-family: Circular-Bold, serif;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
}

.heading-small {
  font-family: Circular-Bold, serif;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
}

.heading-xsmall {
  font-family: Circular-Bold, serif;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 24px;
}

.heading-tiny {
  font-family: Circular-Bold, serif;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
}

.paragraph-large {
  font-family: Circular, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.paragraph-normal {
  font-family: Circular, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.paragraph-small {
  font-family: Circular, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
}

.paragraph-xsmall {
  font-family: Circular, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
}

.overline-text {
  font-family: Circular, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.flex-center {
  display: flex;
  justify-content: center;
}

::-webkit-scrollbar,
::-webkit-scrollbar:vertical {
  width: 8px !important;
}
::-webkit-scrollbar:horizontal {
  height: 8px !important;
}

/* Track */
::-webkit-scrollbar,
::-webkit-scrollbar-corner,
::-webkit-scrollbar-track-piece,
::-webkit-scrollbar-track {
  background: #f2f3f5 !important;
  width: 6px !important;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #dadce3 !important;
  border-radius: 8px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.popover-body {
  padding: 0 !important;
  min-width: 60px;
}

.popover-item {
  padding: 3px 6px;
  border-radius: 4px;
  cursor: pointer;
}

.popover-item:hover {
  background-color: #f2f3f5;
}

.nofocus {
  outline: none;
}

blockquote {
  padding: 15px;
  margin: 15px;
  border-style: dashed;
  border-color: #272e5f;
  border-width: 2px;
}
