.backdrop,
.highlights {
  height: 150px;
  font-family: Circular-Medium, serif;
}

.highlights {
  padding-left: 7px;
  border-radius: 8px;
  line-height: 18px;
  font-size: 16px;
}

.instructionsContainer {
  height: 150px;
  padding: 0px;
  position: relative;
}

.backdrop {
  width: 100%;
  position: absolute;
  border-radius: 8px;
  background-color: #fff;
  overflow-y: auto;
}

.highlights {
  width: 100%;
  padding: 2px 0px 2px 7px;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: transparent;
}

.tooLongPiece {
  background-color: #f7b1c3;
}

.badgeStyle {
  height: 24px;
  padding: 2px 8px;
  margin-bottom: 12px;
  border-radius: 8px;
  background-color: white;
  font-size: 14px;
  line-height: 16px;
}
.badgeStyleGray {
  height: 24px;
  padding: 2px 8px;
  margin-bottom: 12px;
  border-radius: 8px;
  background-color: #f3f3f5;
  font-size: 14px;
  line-height: 16px;
}
input:focus,
textarea:focus {
  box-shadow: 0 0 10px #719ece;
}
