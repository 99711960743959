.todayButton {
  outline: none;
  border: 1px solid #e1e2e6;
  border-radius: 4px;
  padding: 6px 10px;
  box-sizing: border-box;
}

.todayButton:hover {
  background-color: #e1e2e6;
}

.controlButtons {
  outline: none;
  width: 38px;
  height: 38px;
}

.enabled:hover {
  background-color: #e1e2e6;
  border-radius: 50%;
}
