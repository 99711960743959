.styledTextarea {
  background-color: #ffffff;
  border: 1px solid #dadce3;
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.011em;
  color: #01052f;
  min-height: 96px;
  max-height: 600px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 16px;
  width: 100%;
}
.styledTextarea:focus {
  box-shadow: 0 0 10px #719ece;
}
.charNum {
  color: #dadce3;
  font-size: 12px;
  line-height: 12px;
  height: 12px;
  margin-right: 10px;
  margin-bottom: 8px;
}
.error {
  border: 2px solid #f73843;
}
