table,
th,
td {
  border-collapse: collapse;
}
table th {
  color: #808297;
}

table,
th,
tr,
td {
  border: none;
  padding: 0px !important;
  margin: 0px !important;
}

td,
th {
  white-space: nowrap;
}
